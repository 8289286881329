import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as shortId from 'shortid';
import * as global from '../config/globals'

// Rxjs
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { TimeoutError } from 'rxjs';

// import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/retryWhen';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/operator/scan';
import 'rxjs/add/operator/zip';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/range';
import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/toPromise';

import { map } from 'rxjs/operators';
import { UserTokenResponse } from '../models/user-token-response';
import { Credentials } from '../models/credentials';
import { User } from '../models/user';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public idToken: string = "";

  public isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(null)
  public currentUser: BehaviorSubject<User> = new BehaviorSubject(null)

  public gettingAuthStatus: boolean = false;
  public settingAuthStatus: boolean = false;

  constructor(
    private _storage: StorageService,    
    private _http: HttpClient,
    public jwtHelper: JwtHelperService
  ) { 
    shortId.characters('0123456789abcdefghjkmnopqrstuvwxyzABCDEFGHJKMNOPQRSTUVWXYZ$#!=%&');
  }

  public logout() {
    // this._storage.remove('id_token');
    this.idToken = null;
    this.isLoggedIn.next(false);
    this.currentUser.next(null);
  }

  public loadUser() {
    return this._http
      .get(`${global.API_ENDPOINT}/user/profile.json`)
      .pipe(
        map((user: User) => {
          this.currentUser.next(user);
        }))
  }
    
  public saveCurrentUser(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._http
        .put(`${global.API_ENDPOINT}/user/profile.json`, {user: this.currentUser.getValue()})
        .pipe(
          map((data: UserTokenResponse) => {
            this.currentUser.next(data.user);
            this.idToken = data.auth_token;
            this._storage.set('id_token', this.idToken);          
          })
        ).subscribe((response) => {
            resolve(true);
          }, (err) => {
            reject(err);
        });
    });
  }

  public login(email: string, password: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let creds: Credentials = {
        email: email,
        password: password
      }
      this._http
        .post(`${global.API_ENDPOINT}/auth.json`, { user: creds })
        .pipe(
          map((data: UserTokenResponse) => {
            this.idToken = data.auth_token;
            this._storage.set('id_token', this.idToken)
            this._storage.set('email', email);
            this._storage.set('password', password);
            this.isLoggedIn.next(true);
            this.currentUser.next(data.user);
            resolve(true)
            return data.user;
          })
        ).subscribe({ 
          error: (err: any) => reject(err)
        });

    })
  }

  public getAuthStatus(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.gettingAuthStatus = true;
      let token:any = this._storage.get('id_token')
      if(token == null) {
        this.gettingAuthStatus = false;
        this.logout();
        resolve(false)
        return
      }
      if(this.jwtHelper.isTokenExpired(null, token) == true) {
        this.idToken = token;
        this.loadUser().subscribe((user) => {
          this.gettingAuthStatus = false;
          this.isLoggedIn.next(true);
          resolve(true)
        }, (error) => {
          this.gettingAuthStatus = false;
          if(error instanceof TimeoutError) {
            // this.connectionService.testConnections()
            this.logout();
          } else {
            // Failed auth for some reason - different server?
            this.logout();
          }
          resolve(false)
        })
      } else { // token has expired, try and re-login
        let anon_id: any = this._storage.get('email')
        let anon_key: any = this._storage.get('password')
        if(anon_id && anon_key) {
          this.login(anon_id, anon_key).then(() => {
            resolve(true);
          });
        } else {
          this.logout();
          resolve(false)
        }        
      }
    })
  }

}
