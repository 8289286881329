import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent }                 from './header/header.component';
import { SidebarComponent }                from './sidebar/sidebar.component';
import { SidebarRightComponent }           from './sidebar-right/sidebar-right.component';
import { TopMenuComponent }                from './top-menu/top-menu.component';
import { FooterComponent }                 from './footer/footer.component';
import { PanelComponent }                  from './panel/panel.component';
import { FloatSubMenuComponent }           from './float-sub-menu/float-sub-menu.component';
import { AuthImagePipe } from './auth-image.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    SidebarRightComponent,
    TopMenuComponent,
    FooterComponent,
    PanelComponent,
    FloatSubMenuComponent,
    AuthImagePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    SidebarRightComponent,
    TopMenuComponent,
    FooterComponent,
    PanelComponent,
    FloatSubMenuComponent,
    AuthImagePipe
  ]
})
export class GlobalModule { }
