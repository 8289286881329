import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  constructor(
    private authService: AuthService, 
    private router: Router
  ) {}

  canActivateChild(): Promise<boolean> {
    return this.authService.getAuthStatus().then(() => {
      if(this.authService.isLoggedIn.getValue() == true) {
        return true;
      } else {
        this.router.navigate(['login']);
        return false;
      }
    })
  }
  
}
