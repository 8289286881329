import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import pageSettings from '../../../config/page-settings';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'login-v1',
  templateUrl: './login-v1.html'
})

export class LoginV1Page implements OnDestroy {
  pageSettings = pageSettings;

  public login = {
    email: null,
    password: null
  }

  constructor(
    private router: Router,
    private _authService: AuthService
  ) {
    this.pageSettings.pageEmpty = true;
  }

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
  }

  formSubmit(f: NgForm) {
    this._authService.login(this.login.email, this.login.password).then(async (loggedIn) => {
      // await loader.dismiss();
      this.router.navigate(['/cavicam-groups']);
    }, async (rejection) => {
      // loader.dismiss();
      // const alert = await this.alertCtrl.create({
      //   header: 'Authentication Failed',
      //   subHeader: 'Either your ID or key is incorrect',
      //   buttons: [{ text: 'OK'}]
      // });
      // await alert.present();
    });

  }
}
