import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private storage: LocalStorageService
  ) {}

  public set(key: string, value:any) {
    this.storage.store(key, value);
  }

  public get(key: string) {
    return this.storage.retrieve(key);
  }

  public remove(key: string) {
    this.storage.clear(key)
  }

}
